import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Textlink from './Textlink';
import Icon from './Icon';
import { gapable, textColorable } from '../helpers/traits';
import { fontSizes, colors } from '../helpers/variables';

/** Styles für links */
const linkStyles = css`
    ${fontSizes.xs};
    border-left: 1px solid ${colors.black};
    color: ${colors.primary};
    display: inline-block;
    padding: 0 1em;
    text-decoration: none;
    transition: 0.25s color;

    &:first-child {
        border-left: none;
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }

    &:hover {
        color: ${colors.primary};
    }
`;

/** Wrapper */
const Wrapper = styled.nav`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    ${fontSizes.s};
    ${gapable()};
    ${textColorable('black')};

    .navlink--is-active {
        ${linkStyles};
        color: ${colors.primary};
        text-decoration: underline;
    }
`;

/** Link */
const Link = styled(Textlink)`
    ${linkStyles};
`;

/** Optionaler Icon */
const StyledIcon = styled(Icon)`
    margin-right: 0.5em;
`;

/**
 * Erzeugt eine Meta-Navigation
 * @param {array} props.items Die Elemente in der Navigation
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 *
 * @example
 *      <MetaNavigation
 *          textColor="white"
 *          gap={gaps()}
 *          items={[
 *              { title: 'Händlersuche', href: '#' },
 *              { title: 'Ansprechpartner', href: '#' },
 *              { title: 'FAQ', href: '#' },
 *          ]}
 *      />
 */
const MetaNavigation = ({ className, items, gap, textColor }) => (
    <Wrapper className={className} gap={gap} textColor={textColor}>
        {items.map(item => (
            <Link key={`link--${item.title}`} to={item.href}>
                {item.icon && <StyledIcon type={item.icon} />}
                {item.title}
            </Link>
        ))}
    </Wrapper>
);

MetaNavigation.propTypes = {
    className: PropTypes.string,
    textColor: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
            icon: PropTypes.string,
        })
    ).isRequired,
    gap: gapable.propType,
};

MetaNavigation.defaultProps = {
    textColor: null,
    className: null,
    gap: null,
};

export default MetaNavigation;
