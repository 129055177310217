import React from 'react';
import { rem } from 'polished';
import CookieConsent from 'react-cookie-consent';
import styled from 'styled-components';

import Textlink from './Textlink';
import { fontFamilies, colors } from '../helpers/variables';

const StyledLink = styled(Textlink)`
    margin-left: 0.25em;
    text-decoration: underline;
`;

const CookieControl = () => (
    <CookieConsent
        acceptOnScroll
        debug={false}
        buttonText="Verstanden"
        expires={90}
        style={{
            alignItems: 'center',
            backgroundColor: colors.white,
            color: colors.text,
            boxShadow: '0 0 3px 3px rgba(0,0,0,0.2)',
        }}
        contentStyle={{
            lineHeight: '1.5em',
            color: colors.text,
            margin: 'var(--consent-content-margin)',
        }}
        buttonStyle={{
            backgroundColor: colors.primary,
            color: colors.white,
            fontFamily: fontFamilies.default,
            cursor: 'pointer',
            padding: '0.35em 1em 0.45em',
            borderRadius: `${rem(20)}`,
        }}
    >
        <>
            Wir verwenden ausgewählte Cookies um unsere Website stetig für Sie zu verbessern. Durch
            Nutzung derselben erklären Sie sich damit einverstanden.
            <StyledLink to="/datenschutz">Mehr dazu</StyledLink>
        </>
    </CookieConsent>
);

export default CookieControl;
